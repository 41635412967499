.text_small_1 {
  font-size: 12px;
  font-style: italic;
}

@media only screen and (max-width: 640px) {
  .footer-social-links {
    width: 93px;
  }
}

.homepage-product-mobile__content--b {
  margin-top: 0;
}

@font-face {
  font-family: 'Conv_OptimaEL-Text';
  src: url('#{$netstorage-font-path}OptimaDisplay/OptimaDisplay-Roman.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

.brand-aerin .page-main {
  font-family: 'Optima Display Roman' !important;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Optima Display Roman' !important;
  }
}

.woa-blog__content .ee-disco-more__label {
  font-family: 'Optima Display Roman' !important;
}

.brand-aerin .page-main {
  .text--short,
  label,
  .cart-item__qty {
    font-family: 'Optima Display Roman' !important;
  }
}
